var default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
var resize_width = window.innerWidth;
var break_point = 767;
$(function() {
	var default_height = $(document).innerHeight();//アクセスした時の高さを取得
  /*------------------------*/
  //リンクの範囲を広くする
  /*------------------------*/
  $(".widelink").on('click',function(){
    window.location=$(this).find("a").attr("href");
    return false;
	});

	/*------------------------*/
  //ページトップ
  /*------------------------*/
	var topBtn = $('.pageTop');
  if (default_width >= break_point) {
    topBtn.hide();
  }
	// スクロールが200に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 200) {
				topBtn.fadeIn();
		} else if ($(this).scrollTop() < 200) {
				topBtn.fadeOut();
		} else {
      topBtn.fadeIn();
    }
	});
	// スクロールしてトップ
	topBtn.on('click',function () {
		$('body,html').animate({
				scrollTop: 0
		}, 500);
		return false;
  });

  //スムーススクロール（ページ内リンク）
  $('.tocList__item a[href^="#"],.js-scroll').on("click", function () {
    var f = 600;
    var e = $(this).attr("href");
    var g = $(e == "#" || e == "" ? "html" : e);
    var d = g.offset().top;
    $("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });

  //tab
  $('.tabMenu .tabMenu__item').on('click',function() {
    var i = $(this).index();
    // クリック済みタブのデザインを設定したcssのクラスを一旦削除
    $(this).siblings().removeClass('active');
    // クリックされたタブにクリック済みデザインを適用する
    var dataText = $(this).attr('data-card-color');
    $(this).parent().next(".tabItem").attr('data-card-color',dataText);
    $(this).addClass('active');
    // コンテンツを一旦非表示にし、クリックされた順番のコンテンツのみを表示
    $(this).parent().next(".tabItem").children(".list").fadeOut(200).removeClass("show").eq(i).fadeIn(200).addClass("show");
  });
  $(".tabMenu .menuCover").mouseover(function() {
    $(this).fadeOut(200);
  });

  //accordion
  $(".js-accordion .js-accordion__btn").on('click',function() {
    $(this).toggleClass("open");
    $(this).next(".js-accordion__item").slideToggle();
  });

  //pulldown
  $(".js-pulldown .js-pullDownList").on('click',function() {
    $(this).toggleClass("is-show").find(".js-pullDownItem").not(":first").slideToggle();
  });
  //SPヘッダーサーチ
  $('.btn--search').on('click', function() {
    if ($(this).hasClass("is-open")) {
      $(".headerNav__cover,#search").fadeOut();
      $('.btn--search').removeClass("is-open");
    } else {
      $(this).addClass("is-open");
      //カバー表示
      $(".headerNav__cover,#search").fadeIn();
    }
  });
  //SPヘッダーサーチを閉じるときの動作
    $('.headerNav__cover').on('click', function() {
    $(".headerNav__cover,#search").fadeOut();
    $('.btn--search').removeClass("is-open");
  });

  //追従バナー
  var fixB = $(".fixedBtn");
  if (fixB.length) {
    fixB.hide();
    $(window).on('scroll',function () {
      var doch = $(document).innerHeight(), //ページ全体の高さ
      winh = $(window).innerHeight(), //ウィンドウの高さ
      bottom = doch - winh, //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
      footh = $(".footer").innerHeight();
      if ($(this).scrollTop() > 100 && $(this).scrollTop() < bottom - footh) {
        fixB.fadeIn();
      } else {
        fixB.fadeOut();
      }
    });
  }
  //scroll時のヘッダー変化
  var $win = $(window),
      $header = $('.header'),
      headerPos = $('.header').height() + ($win.height() / 5),
      scrollClass = 'scroll';
  $win.on('load scroll', function() {
    var value = $(this).scrollTop();
    if ( value > headerPos ) {
      $header.addClass(scrollClass);
    } else {
      $header.removeClass(scrollClass);
    }
  });

  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  var getDevice = (function() {
  var ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-------------------------------------------------------------------------*/
      /* PCの時
      /*-------------------------------------------------------------------------*/
      $(".widelink").hover(
        function(e) {
            $(this).addClass("hover");
        },
        function(e) {
            $(this).removeClass("hover");
        }
      );
    }
  })();

	/*------------------------*/
	//バーガーメニュー表示・非表示
	/*------------------------*/
	//バーガーボタンクリック
	$(".burger").on('click',function() {
		default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
		if (default_width <= break_point) {
			$(".burger").toggleClass("is-open");
      $(".gNav").slideToggle();
		}
	});
  //カバーをクリック
	$(".gNav a[href]").on('click',function() {
    if (default_width <= break_point) {
      $(".gNav").slideToggle("active");
  		$(".burger").removeClass("is-open");
    }
  });
  
	/*------------------------*/
	//リサイズ時の処理（リサイズした瞬間に走る）
	/*------------------------*/
	$(window).on('resize',function() {
		if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
			return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
		} else {
			default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
			resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ時）
				/*------------------------*/
        //バーガーメニューを開いたままリサイズされた時の処理
        $("#search").css("display","");
        $(".burger").removeClass("is-open");
        $(".headerNav__cover,#search").fadeOut();
        $('.btn--search').removeClass("is-open");
			} else {
				/*------------------------*/
				//PC処理（リサイズ時）
				/*------------------------*/
        $("#search").css("display","block");
			}
		}
	});

	/*------------------------*/
	//リサイズ完了時点処理（完了するまで処理されない）
	/*------------------------*/
	var timer = false;
	$(window).on('resize',function() {
		if (timer !== false) {
				clearTimeout(timer);
		}
		timer = setTimeout(function() {
			resize_width = $(window).innerWidth();//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ完了時）
        /*------------------------*/
        // $header.removeClass(scrollClass);
			} else {
				/*------------------------*/
				//PC処理（リサイズ完了時）
        /*------------------------*/
			}
		}, 200);
	});
});

/*------------------------*/
//関数
/*------------------------*/
/*トップページMV　スライダー*/
var sliderMv = function() {
	$(function() {
    //slider設置
    var slider = ".slider--mv"; // スライダー
    var thumbnailItem = ".slider--mv--dots .dotsItem"; // サムネイル画像アイテム
     // サムネイル画像アイテムに data-index でindex番号を付与
  $(thumbnailItem).each(function(){
    var index = $(thumbnailItem).index(this);
    $(this).attr("data-index",index);
   });
   // スライダー初期化後、カレントのサムネイル画像にクラス「thumbnail-current」を付ける
   // 「slickスライダー作成」の前にこの記述は書いてください。
   $(slider).on('init',function(slick){
    var index = $(".slide-item.slick-slide.slick-current").attr("data-slick-index");
    $(thumbnailItem+'[data-index="'+index+'"]').addClass("thumbnail-current");
   });
   //slickスライダー初期化
   $(slider).slick({
     autoplay: true,
     arrows: false,
     fade: true,
     infinite: true
   });
   //サムネイル画像アイテムをクリックしたときにスライダー切り替え
   $(thumbnailItem).on('click',function(){
     var index = $(this).attr("data-index");
     $(slider).slick("slickGoTo",index,false);
   });
   
   //サムネイル画像のカレントを切り替え
   $(slider).on('beforeChange',function(event,slick, currentSlide,nextSlide){
     $(thumbnailItem).each(function(){
       $(this).removeClass("thumbnail-current");
     });
     $(thumbnailItem+'[data-index="'+nextSlide+'"]').addClass("thumbnail-current");
   });
	});
}